import styled from 'styled-components';

export const Concerts = styled.div`
  & > div:first-child {
    max-width: 900px;
    margin: 0 auto;
    width: 95%;
    padding: 60px 30px 0;
    @media (max-width: 768px) {
      width: 100%;
      padding: 60px 30px;
      box-sizing: border-box;
    }
    h1 {
      font-size: 24px;
      text-align: center;
      color: #fff;
      font-weight: 400;
      letter-spacing: 1.6px;
      @media (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 8px;
      }
    }
    p {
      text-align: center;
      color: #fff;
      font: 400 1.40625rem 'Nimbus Sans L', Arial, 'Lucida Grande', sans-serif;
      font-size: 21px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
      a {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .view-content {
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 72px;
    @media (max-width: 768px) {
      gap: 42px;
    }
    & > div {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 64px;
      width: 100%;
      gap: 21px;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        padding: 0 30px;
        box-sizing: border-box;
        border-bottom: 1px solid white;
        padding-bottom: 42px;
      }
      .date {
        width: 120px;
        p {
          font-size: 14px !important;
        }
        background: #fff;
        font-weight: 700;
        font-size: 1.09375rem;
        text-transform: uppercase;
        color: #212121 !important;
        padding: 0 8px;
        text-align: center;
        font-family: HelveticaNeue-Light, 'Helvetica Neue Light',
          'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
      }
      .content_middle {
        width: calc(100% - 300px);
        @media (max-width: 768px) {
          width: 100%;
        }
        & > p {
          font-family: 'Nimbus Sans L', Arial, 'Lucida Grande', sans-serif !important;
          margin-bottom: 0;
          font-size: 18px;
          letter-spacing: 0.03em;
          font-weight: 400;
          @media (max-width: 768px) {
            text-align: center;
            margin-top: 6px;
          }
        }
        & > div {
          display: flex;
          gap: 16px;
          @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            text-align: center !important;
          }
          h3 {
            width: 55%;
            color: #fff !important;
            font-family: 'Druk Wide Web', futura-pt, HelveticaNeue-Light,
              'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
              'Lucida Grande', sans-serif;
            font-size: 22px;
            font-weight: 400;
            margin-top: -6px;
            text-transform: uppercase;
            @media (max-width: 768px) {
              width: 100%;
            }
          }
          p {
            font-family: 'Nimbus Sans L', Arial, 'Lucida Grande', sans-serif !important;
            margin-bottom: 0;
            font-size: 18px;
            letter-spacing: 0.03em;
            font-weight: 400;
            text-transform: uppercase;
            @media (max-width: 768px) {
              text-align: center;
            }
          }
        }
      }
      .last_div {
        width: 200px;
        @media (max-width: 768px) {
          margin: 0 auto;
          text-align: center !important;
        }
        p {
          font-family: 'Nimbus Sans L', Arial, 'Lucida Grande', sans-serif !important;
          margin-bottom: 0;
          font-size: 18px;
          letter-spacing: 0.03em;
          font-weight: 400;
          display: flex;
          align-items: center;
          @media (max-width: 768px) {
            margin: 0 auto;
            text-align: center !important;
            justify-content: center;
          }
          img {
            max-width: 10px;
            height: 10px;
            margin-left: 5px;
            margin-top: 3px;
          }
        }
        p:last-child {
          transition: 0.3s ease-in-out;
          width: fit-content;
          cursor: pointer;
          @media (max-width: 768px) {
            text-align: center !important;
          }
          &:hover {
            background: #fff;
            color: #000;
            width: fit-content;
          }
        }
      }
    }
  }
`;
