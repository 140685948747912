import styled from 'styled-components';

export const Hero = styled.div`
  &:after {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(18, 15, 6, 0) 69.39%,
      rgba(18, 15, 6, 0.08) 80.57%,
      rgba(0, 0, 0, 0.66) 87.47%,
      #000 99.13%
    );
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition: all 0.2s ease-in;
    opacity: 1;
  }
  height: calc(100vh - 55px);
  width: 100%;
  background-image: url('/images/tour-image-desktop.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @media (max-width: 768px) {
    background-image: url('/images/tour-bg-eu-mobile.jpg');
  }
  div:first-child {
    position: absolute;
    z-index: 3;
    top: 80px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: 315px;
    img {
      max-width: 100%;
      height: auto;
      -ms-interpolation-mode: bicubic;
      display: inline-block;
      vertical-align: middle;
    }
  }
  div:last-child {
    width: 100%;
    padding: 0;
    margin-bottom: 0;
    text-align: center;
    position: absolute;
    bottom: 30px;
    left: 0;
    z-index: 2;
    @media (max-width: 768px) {
      bottom: 60px;
    }
    img {
      max-width: 100%;
      height: auto;
      -ms-interpolation-mode: bicubic;
      display: inline-block;
      vertical-align: middle;
      @media (max-width: 768px) {
        max-width: 340px;
      }
    }
  }
`;

export const Partners = styled.div`
  display: flex;
  justify-content: center;
  img {
    max-width: 350px;
    height: auto;
    -ms-interpolation-mode: bicubic;
    display: inline-block;
    vertical-align: middle;
  }
`;
