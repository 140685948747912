import * as S from './styles';

const Loader = () => {
  return (
    <S.Loader className='center'>
      <div className='wave' />
      <div className='wave' />
      <div className='wave' />
      <div className='wave' />
      <div className='wave' />
      <div className='wave' />
      <div className='wave' />
      <div className='wave' />
      <div className='wave' />
      <div className='wave' />
      <p className='loading'>Loading the Base Data. Please wait...</p>
    </S.Loader>
  );
};

export default Loader;
