import * as S from './styles';
import { useHistory } from 'react-router-dom';
const ConcertItem = ({ data }) => {
  const history = useHistory();
  return (
    <S.ConcertItem
      onClick={() =>
        !data?.sold_out &&
        history.push(
          `/concert-tickets/buy/8127dhasmdaiJAOSQ98312?date=${data?.date.toString()}&ct=${
            data?.country
          }&id=${data.stadiumName}`
        )
      }>
      <div className='date'>
        <p>{data?.date.split(',')[0]}</p>
        <p>{data?.date.slice(-2)}</p>
        <p>{data?.date.split(' ')[1]} 2024</p>
      </div>
      <div className='content'>
        <h2>
          {data?.country} | {data?.stadiumName}
        </h2>
        <h5>Weeknd: After Hours Til Dawn</h5>
        <span>NEW</span>
      </div>
      <button>{data?.sold_out ? 'Sold Out' : 'View Tickets'}</button>
    </S.ConcertItem>
  );
};

export default ConcertItem;
