export const concerts = [
  {
    city: 'Australia',
    country: 'Australia',
    stadiumName: 'Marvel Stadium',
    date: 'Sat, Oct 5',
    location: 'Melbourne',
  },
 
  {
    city: 'Australia',
    country: 'Australia',
    stadiumName: 'Marvel Stadium',
    date: 'Sun, Oct 6',
    location: 'Melbourne',
  },
  {
    city: 'Australia',
    country: 'Australia',
    stadiumName: 'Accor Stadium',
    date: 'Tue, Oct 22',
    location: 'Sydney',
  },
  

  {
    city: 'Australia',
    country: 'Australia',
    stadiumName: 'Accor Stadium',
    date: 'Wed, Oct 23',
    location: 'Sydney',
  },
  

 
  
  
];
