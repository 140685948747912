import * as S from './styles';

const TopBanner = () => {
  return (
    <S.TopBanner>
      <div id='standard-banner'>
        <div
          dir='ltr'
          className='ltr appWrapper adp-ADPBanner'
          data-component='AppWrapper'
          data-ui='react'
          data-uiframework='mui'
          data-locale='en-GB'>
          <div id='standard-banner-wrapper' className='css-1qibcwi'>
            <div className='css-18n14td' />
            <div id='standard-banner' className='css-133kpae'>
              <picture data-testid='image-container' className='css-1pmhwip'>
                <noscript />
                <source
                  data-testid='image-source'
                  media='(max-width: 320px)'
                  srcSet='https://www.thesenior.com.au/images/transform/v1/crop/frm/172575538/43348892-b280-4652-b205-d911a563fbe7.jpg/r1_0_1919_1079_w1920_h1080_fmax.jpg'
                />
                <source
                  data-testid='image-source'
                  media='(max-width: 768px)'
                  srcSet='hhttps://www.thesenior.com.au/images/transform/v1/crop/frm/172575538/43348892-b280-4652-b205-d911a563fbe7.jpg/r1_0_1919_1079_w1920_h1080_fmax.jpg'
                />
                <source
                  data-testid='image-source'
                  media='(max-width: 1024px)'
                  srcSet='https://www.thesenior.com.au/images/transform/v1/crop/frm/172575538/43348892-b280-4652-b205-d911a563fbe7.jpg/r1_0_1919_1079_w1920_h1080_fmax.jpg'
                />
                <img
                  data-testid='image'
                  src='https://www.thesenior.com.au/images/transform/v1/crop/frm/172575538/43348892-b280-4652-b205-d911a563fbe7.jpg/r1_0_1919_1079_w1920_h1080_fmax.jpg'
                  alt='The Weeknd'
                  className='css-11uwtq5'
                />
              </picture>
            </div>
            <div className='css-jhufcc' />
            <div className='css-1c9i981'>
              <div className='banner-content'>
                <div className='favourite-artist'>
                  <div
                    dir='ltr'
                    className='ltr appWrapper adp-favouriteIconButton'
                    data-component='AppWrapper'
                    data-ui='react'
                    data-uiframework='mui'
                    data-locale='en-GB'>
                    <a href='/login/addfavourite?id=474869&type=Artist&name=The%20Weeknd'>
                      <button
                        className='MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium jss29 css-1yxmbwk'
                        tabIndex={0}
                        type='button'
                        aria-label='Add to Favourites'>
                        <svg
                          viewBox='0 0 100 100'
                          width='1em'
                          height='1em'
                          focusable='false'
                          aria-hidden='true'
                          fill='currentColor'>
                          <path d='M50 95.9l-2.2-2.4c-2.3-2.4-4.4-4.2-8.6-7.6-2.6-2.1-5.9-4.8-10.4-8.6C16.8 67.2.5 53.5.5 33.8c0-7.1 2-13.6 5.7-18.7 1.8-2.4 3.9-4.5 6.3-6.3 2.4-1.7 5-2.9 7.8-3.7C26 3.5 32 3.8 37.5 6.1c4.7 2 9 5.3 12.5 9.7C53.5 11.3 57.8 8 62.5 6 68.1 3.7 74 3.3 79.7 5c2.8.8 5.4 2 7.8 3.7 2.4 1.7 4.5 3.8 6.3 6.2 3.7 5.1 5.7 11.6 5.7 18.6 0 4.7-.9 9.4-2.8 14-1.6 3.9-3.9 7.9-7 11.8-5.3 6.8-12 12.6-18.5 17.9C69 79 67 80.6 65.3 82c-7.6 6.2-10.1 8.2-13.2 11.5L50 95.9zM27.4 10.2c-1.8 0-3.6.3-5.4.8-4.3 1.2-8.1 3.9-10.8 7.7-3 4.1-4.6 9.4-4.6 15.1 0 7.4 2.8 14.5 8.5 21.8 5.1 6.5 11.7 12 17.4 16.9 4.5 3.8 7.8 6.5 10.4 8.6 3 2.5 5.1 4.2 7 5.9 2.6-2.5 5.6-5 11.6-9.8 1.7-1.4 3.6-2.9 5.9-4.8 5.8-4.7 12.3-10.3 17.5-16.9 5.7-7.4 8.5-14.6 8.5-22 0-5.7-1.6-10.9-4.5-15-2.7-3.8-6.5-6.4-10.8-7.6-4.3-1.2-8.9-1-13.1.8-4.8 2-9 5.8-12.3 11L50 27l-2.6-4.2c-3.3-5.2-7.5-9-12.2-11-2.6-1-5.2-1.6-7.8-1.6z' />
                        </svg>
                        <span className='MuiTouchRipple-root css-w0pj6f' />
                      </button>
                    </a>
                  </div>
                </div>
                <h1
                  className='css-hbrx4q'
                  style={{
                    margin: '0px',
                    textShadow: 'rgba(0, 0, 0, 0.6) 0px 2px 4px',
                  }}>
                  Weeknd
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.TopBanner>
  );
};

export default TopBanner;
