import * as S from './styles';

const Products = () => {
  return (
    <S.Products>
      <div>
        <div>
          <picture>
            {/*[if IE 9]><video style="display: none;"><![endif]*/}
            <source
              srcSet='https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_mobile-sm_sq/public/image%208%20%281%29.png?itok=S-BakIco 1x, https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_mobile-md_sq/public/image%208%20%281%29.png?itok=1ZdsnovB 1.5x, https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_mobile-lg_sq/public/image%208%20%281%29.png?itok=Lp_7ljd4 2x'
              media='(max-width: 320px)'
            />
            <source
              srcSet='https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_mobile-md_sq/public/image%208%20%281%29.png?itok=1ZdsnovB 1x, https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_tablet_sq/public/image%208%20%281%29.png?itok=z_Lj_2qx 1.5x, https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_tablet_sq/public/image%208%20%281%29.png?itok=z_Lj_2qx 2x'
              media='(min-width: 321px)'
            />
            {/*[if IE 9]></video><![endif]*/}
            
          </picture>
          
        </div>
        <div>
          <picture>
            {/*[if IE 9]><video style="display: none;"><![endif]*/}
            <source
              srcSet='https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_mobile-sm_sq/public/DFMCOLLECTORS01FRONT_800x.png?itok=buiLfJbX 1x, https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_mobile-md_sq/public/DFMCOLLECTORS01FRONT_800x.png?itok=7Jt5ZcrF 1.5x, https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_mobile-lg_sq/public/DFMCOLLECTORS01FRONT_800x.png?itok=BFNEyzog 2x'
              media='(max-width: 320px)'
            />
            <source
              srcSet='https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_mobile-md_sq/public/DFMCOLLECTORS01FRONT_800x.png?itok=7Jt5ZcrF 1x, https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_tablet_sq/public/DFMCOLLECTORS01FRONT_800x.png?itok=l5dIOQw7 1.5x, https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_tablet_sq/public/DFMCOLLECTORS01FRONT_800x.png?itok=l5dIOQw7 2x'
              media='(min-width: 321px)'
            />
            {/*[if IE 9]></video><![endif]*/}
            
          </picture>

          
        </div>
        <div>
          <picture>
            {/*[if IE 9]><video style="display: none;"><![endif]*/}
            <source
              srcSet='https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_mobile-sm_sq/public/DAWN-FM-COLLECTORS-02%20CD.png?itok=0xaaZDXs 1x, https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_mobile-md_sq/public/DAWN-FM-COLLECTORS-02%20CD.png?itok=jUZneYR9 1.5x, https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_mobile-lg_sq/public/DAWN-FM-COLLECTORS-02%20CD.png?itok=N552wykX 2x'
              media='(max-width: 320px)'
            />
            <source
              srcSet='https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_mobile-md_sq/public/DAWN-FM-COLLECTORS-02%20CD.png?itok=jUZneYR9 1x, https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_tablet_sq/public/DAWN-FM-COLLECTORS-02%20CD.png?itok=2oApq8_Z 1.5x, https://www.theweeknd.com/sites/g/files/aaj15616/f/styles/suzuki_breakpoints_image_tablet_sq/public/DAWN-FM-COLLECTORS-02%20CD.png?itok=2oApq8_Z 2x'
              media='(min-width: 321px)'
            />
            {/*[if IE 9]></video><![endif]*/}
            
          </picture>

          
        </div>
      </div>
    </S.Products>
  );
};
export default Products;
