import * as S from './styles';

const Hero = () => {
  return (
    <>
      <S.Hero>
        
        <div>
          
        </div>
      </S.Hero>
      <S.Partners>
        <div>
          
        </div>
      </S.Partners>
    </>
  );
};

export default Hero;
