import * as S from './styles';
import { Link, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
const Concerts = () => {
  const concerts = [
{
      city: 'Australia',
      country: 'Australia',
      stadiumName: 'Marvel Stadium',
      date: 'Sat, Oct 5',
      location: 'Melbourne',
    },
    {
      city: 'Australia',
      country: 'Australia',
      stadiumName: 'Marvel Stadium',
      date: 'Sun, Oct 6',
      location: 'Melbourne',
    },
    {
      city: 'Australia',
      country: 'Australia',
      stadiumName: 'Accor Stadium',
      date: 'Tue, Oct 22',
      location: 'Syndey',
    },
  
    {
      city: 'Australia',
      country: 'Australia',
      stadiumName: 'Accor Stadium',
      date: 'Wed, Oct 23',
      location: 'Sydney',
    },
   

  ];
  const history = useHistory();
  return (
    <S.Concerts>
      <div>
        <h1>Weeknd: After Hours Til Dawn</h1>
      
      </div>

      <div className='view-content'>
        {concerts.map((elem, i) => (
          <div key={i}>
            <div className='date'>
              <p>{elem.date}</p>
            </div>
            <div className='content_middle'>
              <div>
                <h3>{elem.stadiumName}</h3>
                <p>
                  {elem.location}, {elem.city}
                </p>
              </div>
            </div>
            <div className='last_div'>
              <p>TICKETS</p>
              <p
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.push('/concert-tickets');
                }}>
                Available now <img src='/images/back_white.png' />
              </p>
            </div>
          </div>
        ))}
      </div>
    </S.Concerts>
  );
};

export default Concerts;
