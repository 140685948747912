import * as S from './styles';
import ConcertItem from '../ConcertItem';
import { concerts } from '../../../concerts';
const TicketsList = () => {
  return (
    <S.TicketsList>
      <h1>Events</h1>
      <div className='international'>
        <p>
          Australia <span>(4)</span>
        </p>
      </div>
      <S.ItemsList>
        {concerts.map((elem, i) => (
          <ConcertItem data={elem} key={i} />
        ))}
      </S.ItemsList>
      
      <p className='genres'>
        Genres <span>Pop</span> <i>|</i> <span>R&B/Soul</span>
      </p>
    </S.TicketsList>
  );
};

export default TicketsList;
