import TicketsList from '../TicketsList';
import * as S from './styles';

const Main = () => {
  return (
    <S.Main>
      <TicketsList />
      <S.Aside>
        <a
          id='aw0'
          target='_blank'
          href='https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjsuOMP-slL5EHwtQ6cjq6BWpbp1wlLTanmvD_GldUYYsdf7Xk4pUUA_O52InGKaugnkDkUf9ee-j6tRVKnOaG7m1wzISamvFLHQ_xzP5k_EQIfNEDJ5PKRD8Fq1Eh2ZWLcAIiqZ2Y9VRF1Sry8DFb6MB7HbB5jVY_t5v5WSYqPY6SQTNH3Nsj7Mxczj8lqaPdf4IgY3p2aFoLnEhh5_AE1wUH6Sbbqpx5I_5KijMlkvrvDSGtlDlNEHERbACyE2DBJdIMmV-urvVH6GFleXWh7r5FogCSjz9Vy-N8eLf4GMjeStFTb-t-EYNhYRJ-L1P8sdlLxpFIA&sai=AMfl-YRT_Qr0ApU87fwBJNAK1fw8dEc27ZEbKcCtwFOh0jrXuXL-ydRZcODqVFCVKbDZFxFv5lEXWPhkYmnI2qPBVf5KG9zLVn2vCkS6NnmKbH_I80hVmLfEtk2fM6LJET-wEA&sig=Cg0ArKJSzM8iqGiV9jid&fbs_aeid=[gw_fbsaeid]&adurl=https://www.ticketmaster.co.uk/americanexpress%3Fcamefrom%3DCFC_AMEX_UK_LN_HPU&nm=4&nx=80&ny=-335&mb=2'
          onfocus="ss('aw0')"
          onmousedown="st('aw0')"
          onmouseover="ss('aw0')"
          onclick="ha('aw0')">
          
        </a>
      </S.Aside>
    </S.Main>
  );
};

export default Main;
