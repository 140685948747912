import * as S from './styles';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [showMobile, setShowMobile] = useState(false);

  return (
    <S.Navbar
      id='page'
      data-offcanvas
      className={`off-canvas-wrap l-page has-no-sidebars ${
        showMobile ? `move-right` : ''
      }`}>
      <aside className='l-off-canvas left-off-canvas-menu'>
        <div className='l-region l-region--off-canvas'>
          <nav
            id='block-menu-block-asf-common-mm-oc'
            role='navigation'
            className='block block--menu-block block--menu-block-asf-common-mm-oc delta--asf-common-mm-oc'>
            <div className='menu-block-wrapper menu-block-asf_common_mm_oc menu-name-main-menu parent-mlid-0 menu-level-1'>
              <ul
                id='main-menu-links'
                className='off-canvas-list links clearfix'>
                <li
                  className='user--menu-link-login'
                  data-menu-parent-id={0}
                  onClick={() => window.scrollTo(0, 0)}>
                  <Link to='/'>Home</Link>
                </li>
                <li
                  className='user--menu-link-login'
                  data-menu-parent-id={0}
                  onClick={() => window.scrollTo(0, 0)}>
                  <Link to='/concert-tickets'>Tickets</Link>
                </li>
                <li className='user--menu-link-login' data-menu-parent-id={0}>
                  <a
                    href='https://xo.store//'
                    target='_blank'
                    rel='noreferrer'>
                    Store
                  </a>
                </li>
                
              </ul>
            </div>
          </nav>
        </div>
      </aside>
      <div
        className='l-page-wrapper inner-wrap'
        data-oc-presentation='move-left'
        data-oc-side='left'>
        <header className='l-header'>
          <section className='l-header-top'>
            <div className='sticky'>
              <nav
                className='l-navigation top-bar'
                role='navigation'
                data-topbar>
                <div className='l-branding'>
                  <h1 className='site-name'>
                    <Link
                      to='/'
                      title='Home'
                      aria-label='Home'
                      rel='home'
                      onClick={() => window.scrollTo(0, 0)}>
                      <span>LIVETICKETS</span>
                    </Link>
                  </h1>
                  <div className='l-region l-region--branding'>
                    <nav
                      id='block-menu-block-asf-common-mm-nav'
                      role='navigation'
                      className='block block--menu-block block--menu-block-asf-common-mm-nav delta--asf-common-mm-nav'>
                      <div className='menu-block-wrapper menu-block-asf_common_mm_nav menu-name-main-menu parent-mlid-0 menu-level-1'>
                        <ul className='menu'>
                          <li
                            className='first leaf menu-mlid-16341'
                            onClick={() => window.scrollTo(0, 0)}>
                            <Link to='/'>Home</Link>
                          </li>
                          <li
                            className='leaf menu-mlid-445 no-smoothstate'
                            onClick={() => window.scrollTo(0, 0)}>
                            <Link to='/concert-tickets'>Tickets</Link>
                          </li>
                          <li className='leaf menu-mlid-35756'>
                            <a
                              href='https://xo.store/'
                              target='_blank'
                              rel='noreferrer'>
                              Store
                            </a>
                          </li>
                          
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                <ul className='left'>
                  <li onClick={() => setShowMobile(!showMobile)}>
                    <a
                      className='left-off-canvas-toggle off-canvas-toggle menu-icon'
                      title='Menu'
                      aria-label='Menu'
                      href='#off-canvas'
                      aria-expanded='true'>
                      <span />
                      <span>Menu</span>
                      <span />
                    </a>
                  </li>
                  <li className='divider' />
                </ul>
                <div className='l-region l-region--navigation'>
                  <div
                    id='block-dotmailer-rest-webform-dm-mail-icon-nav'
                    className='block block--dotmailer-rest-webform block--dotmailer-rest-webform-dm-mail-icon-nav delta--dm-mail-icon-nav'>
                    <div className='block__content'>
                      <a
                        href='mailto:noreply@live-tickets.net'
                        data-target='#mailing-list'
                        className='bolero-modal-link icon icon-mail follow-mail'
                      />
                    </div>
                  </div>
                </div>
                <ul className='right'></ul>
              </nav>
            </div>
            <div className='l-breadcrumb'>
              <h2 className='element-invisible'>You are here</h2>
              <ul className='breadcrumb'>
                <li />
              </ul>
            </div>
          </section>
        </header>
      </div>
    </S.Navbar>
  );
};

export default Navbar;
